@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes highlight {
  0% {
    background: #fef3c7;
  }
  100% {
    background: none;
  }
}

.animate-highlight {
  animation: highlight 600s;
}
